const standard = {
    colors: 
    {
        background: '#121212',
        primary: '#FBC53E',
        light: '#F6F4E6',
        dark: '#41444B',
        darkLight: '#C4C4C4'
    },
    fonts: {
        title: "'Archivo', sans-serif",
        text: "'Poppins', sans-serif",
    }
    
}

export default standard