//THEME PROVIDER DO STYLED-COMPONENTS
import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import standard from '../src/styles/themes/standard'

const GlobalStyles = createGlobalStyle`
  *{
    box-sizing: border-box;
    margin: 0;
    border: 0;
    padding: 0;
  }

  body, html {
    height: 100%;
    background: ${props => props.theme.colors.background};
    scroll-behavior: smooth;
  }
`

export function wrapRootElement({ element }){
  return <ThemeProvider 
  theme={standard}>
    <GlobalStyles />
    {element}
    </ThemeProvider>
}

// caso tivesse um Layout padrão
// seria wrapPageElement e 
// utilizariamos um <<Layout {...props}>{element}</Layout> ao invés de <ThemeProvider..